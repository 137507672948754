import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


const firebaseConfig = {
   apiKey: "AIzaSyDXmxnaicr-Otxcwgsmj1FF4jJv46Uba88",
   authDomain: "news13.firebaseapp.com",
   projectId: "news13",
   storageBucket: "news13.appspot.com",
   messagingSenderId: "702104067350",
   appId: "1:702104067350:web:a62f89cc8d51140c2f0437",
   measurementId: "G-4WRNM2ML1L"
};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
